import React from 'react';
import Thankyou from 'components/thankyou';

export default function ThankyouPage() {
  return (
    <main>
      <Thankyou />
    </main>
  );
}

import React from 'react';
import Container from 'container';
import Headline from 'headline';
import Img from 'img';
import { navigate } from 'gatsby';
import s from './thank_you.module.scss';

export default function ThankYou() {
  const subtitles = [
    'YOUR FORM HAS BEEN',
    'SUBMITTED SUCCESSFULLY!',
    'SOMEONE WILL BE IN TOUCH SOON'
  ];
  return (
    <Container className={s.root}>
      <div className={s.wrapper}>
        <Headline tertiary className={`${s.wild}`} h1 wild html="Thank You!" />
        <div className={s.flex}>
          <Img className={s.img} src="thank-you" />
          {subtitles.map((subtitle, i) => (
            <Headline
              className={` ${s.normal} ${i === 0 && s.first} ${i === 1 &&
                s.second}`}
              h2
              html={subtitle}
            />
          ))}
        </div>
        <div className={s.textWrapper}>
          <p className={s.text}>
            In the meantime, why don't you
            <span
              className={`${s.highlight} ${s.goBack}`}
              onClick={() => navigate('/free-resources/')}
            >
              {' '}
              check out our free resources.{' '}
            </span>
          </p>
          <p className={`${s.text}`}>
            Or you can click here to
            <span
              className={`${s.highlight} ${s.goBack}`}
              onClick={() => navigate('/')}
            >
              {' '}
              keep browsing laurieanne.com{' '}
            </span>
          </p>
        </div>
      </div>
    </Container>
  );
}
